export interface IEnvironment {
    production: boolean;
    apiBaseUrl: string;
    defaultAppUrl: string;
    remitAppUrl: string;
    adminAppUrl: string;
    phiAppUrl: string;
    studiesAppUrl: string;
    clinicAppUrl: string;
    billerAppUrl: string;
    chartsAppUrl: string;
    betaAppUrl: string;
    chartsV2AppUrl: string;
    signatureAppUrl: string;
    signatureKey: string;
}

export enum AvailableApplication {
    Admin = 'admin',
    Remit = 'remit',
    Phi = 'phi',
    Biller = 'biller',
    Studies = 'studies',
    Charts = 'charts',
    Beta = 'beta',
    Clinic = 'clinic',
    ChartsV2 = 'chartsv2',
    Signature = 'sign',
    Vascunote = 'vascunote'
}