import { Injectable } from '@angular/core';
import { Observable, catchError, map, of, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './local-storage.service';
import { Router } from '@angular/router';
import { AvailableApplication } from './environment.interface';
import { DataService } from './data.service';
import { Utils } from './utils';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  userCompanies: any[] = [];
  accessToken: string = '';

  constructor(
    private readonly _localStorageService: LocalStorageService,
    private readonly _router: Router,
    private readonly _dataService: DataService) {
  }

  login(email: string): Observable<any> {
    return this._dataService.post('api/v1/auth', { email });
  }

  validateOTP(otp: string, email: string): Observable<ValidOTPResponse> {
    return this._dataService.post('api/v1/auth/check-code', { code: otp, email });
  }

  getAuthTokenForCompany(userId: string, companyId: string): Observable<AuthToken> {
    return this._dataService.post('api/v1/auth/company_auth', {
      userId,
      companyId
    }, this.accessToken);
  }

  getAccessTokenFromRefreshToken(refreshToken: string): Observable<AuthToken> {
    return this._dataService.post('api/v1/auth/refresh-token', { refreshToken });
  }

  hideOrPinCompany(request: any): Observable<void> {
    return this._dataService.post('api/v1/users/pin-hide-company', request);
  }

  activateAccount(token: string): Observable<any> {
    return this._dataService.post('api/v1/auth/activate', { token });
  }

  getUserLicenses(): Observable<CompanyProductLicense[]> {
    const url = 'api/v1/companies/company_licenses';
    const token = this._localStorageService.authorizationData?.token || '';
    return this._dataService.getWithAuthToken(url, token);
  }

  setUserIpAddress() {
    return this._dataService.get(`https://ipinfo.io/json?token=b67e18a15eba1c`, false)
      .subscribe((res) => {
        this._localStorageService.ipAddress = res?.ip || null
        this._localStorageService.geoLocation = this.getFormattedAddress(res);
      });
  }

  logout(): void {
    this.clearAuthFromLocalStorage()
    this._router.navigateByUrl('login');
  }

  redirectToUrl(url: string) {
    window.location.href = url;
  }

  persistAuthData = (email: string, authResponse: AuthToken): void => {
    let jwtPayload = Utils.parseJwt(authResponse.accessToken);

    const authData = {
      token: authResponse.accessToken,
      refreshToken: authResponse.refreshToken,
      userName: email,
      expiresAt: Utils.getTokenExpireDate(jwtPayload.exp)
    };

    this._localStorageService.authorizationData = authData;
    this.upsertUserCache(authData.userName);
  }

  upsertUserCache(userName: string) {
    let cache = this._localStorageService.userCache;
    if (!cache?.length) {
      cache = [];
    }
    const matchingIndex = cache.findIndex(c => c.userName === userName)
    if (matchingIndex === -1) {
      cache.push({ userName: userName })
    } else {
      cache[matchingIndex] = { userName: userName };
    }
    this._localStorageService.userCache = cache;
  }

  removeAccountFromUserCache(userName: string) {
    let cache = this._localStorageService.userCache;
    if (!cache?.length) {
      return;
    }
    this._localStorageService.userCache = cache.filter(c => c.userName !== userName);
  }

  get cachedUsers(): UserCache[] {
    return this._localStorageService.userCache;
  }

  redirectToUrlWithToken(returnUrl: string) {
    const queryString: string[] = [
      `q=${encodeURIComponent(this._localStorageService.authorizationDataEncrypted)}`
    ]
    if (this._localStorageService.machineId) {
      queryString.push(`d=${encodeURIComponent(this._localStorageService.machineId)}`);
    }
    window.location.href = `${returnUrl}?${queryString.join('&')}`;
  }

  getUrlByAppName(appName: AvailableApplication, appendRedirectUrl: boolean) {
    let url = ''
    switch (appName) {
      case AvailableApplication.Admin:
        url = environment.adminAppUrl;
        break;
      case AvailableApplication.Remit:
        url = environment.remitAppUrl;
        break;
      case AvailableApplication.Phi:
        url = environment.phiAppUrl;
        break;
      case AvailableApplication.Biller:
        url = environment.billerAppUrl;
        break;
      case AvailableApplication.Studies:
        url = environment.studiesAppUrl;
        break;
      case AvailableApplication.Charts:
        url = environment.chartsAppUrl;
        break;
      case AvailableApplication.Clinic:
        url = environment.clinicAppUrl;
        break;
      case AvailableApplication.ChartsV2:
        url = environment.chartsV2AppUrl;
        break;
        case AvailableApplication.Signature:
        url = environment.signatureAppUrl;
        break;
      case AvailableApplication.Beta:
        url = environment.betaAppUrl;
        break;
    }
    if (appendRedirectUrl) {
      return url + '/app-redirect';
    }
    return url;
  }

  clearAuthFromLocalStorage() {
    if (!this._localStorageService.authorizationData) {
      return;
    }
    this._localStorageService.authorizationData = null;
  }

  getProductNamesWithLicenses(): Observable<string[]> {
    return this.getUserLicenses()
      .pipe(map((licenses: CompanyProductLicense[]) => licenses
        //.filter(l => l.name !== 'charts' || (l.name === 'charts' && l.activeBillingPhysicians > 0))
        .map(l => l.name)));
  }

  private getFormattedAddress(geoLocationResponse: any): string {
    let address = '';
    if (!geoLocationResponse) {
      return address
    }
    if (geoLocationResponse.city) {
      address += geoLocationResponse.city
    }
    if (geoLocationResponse.postal) {
      address += `, ${geoLocationResponse.postal}`;
    }
    if (geoLocationResponse.region) {
      address += ` ${geoLocationResponse.region}`;
    }
    if (geoLocationResponse.country) {
      address += `, ${geoLocationResponse.country}`;
    }
    return address;
  }

  get apiBaseUrl(): string {
    return environment.apiBaseUrl;
  }

  get defaultAppUrl(): string {
    return environment.defaultAppUrl;
  }

  get phiAppUrl(): string {
    return environment.phiAppUrl;
  }

  get billerAppUrl(): string {
    return environment.billerAppUrl;
  }

  get isTokenExpired() {
    const authData = this._localStorageService.authorizationData;
    if (!authData?.token || !authData?.expiresAt) {
      return true;
    }
    return new Date(authData.expiresAt) <= new Date();
  }
}

export interface AuthData {
  token: string;
  refreshToken: string;
  expiresAt: Date;
}

export interface UserCache {
  userName: string;
}

export interface LoginResponse {
  phone: string;
  email: string;
}

export interface AuthToken {
  accessToken: string;
  refreshToken: string;
  userType?: UserType;
}

export interface UserCompany {
  id: string;
  companyEmail: string;
  companyId: string;
  isPinned: boolean;
  isHidden: boolean;
  userId: string;
  company: {
    id: string;
    name: string;
  }
}

export interface CompanyProductLicense {
  subscriptionStatus: SubscriptionStatus;
  name: string;
  isEnabled: boolean;
  qty: number;
  totalAllowedBillingPhysicians: number;
  activeBillingPhysicians: number;
  purchaseChannel: PurchaseChannel;
  percentValue: number;
}

export interface AvailableLicenses {
  hasChartsLicense: boolean;
  hasStudiesLicense: boolean;
}

export interface ValidOTPResponse {
  status: 'Token' | 'Companies';
  response: AuthToken | UserCompany[]
  tempAccessToken?: string;
}

export enum SubscriptionStatus {
  Active = 1,
  Suspended
}

export enum PurchaseChannel {
  CommercialDirect = 1,
  CommercialIndirect
}

export enum UserType {
  BillingPhysician = 1,
  Nurse,
  MedicalAssistent,
  NonMedicalPersonal,
  Biller
}